import "./whatsapp.css";
import Arrow from "../../assets/arrow.svg";
import Hero from "../../assets/whatsapp-slide.png";
import { useRef, useEffect, useState } from "react";
import sideimg from "../../assets/whats-app-message.png";
import open from "../../assets/open.png";
import close from "../../assets/close.png";

const faqItems = [
    {
        title: "What is WhatsApp Business API?",
        desciption: "WhatsApp Business API allows you to communicate with customers at scale with bots and advanced functions, such as message broadcast campaigns, links to websites, multiple logins, and many more. "
    },
    {
        title: "Is WhatsApp Business API free?",
        desciption: "WhatsApp Business API allows you to communicate with customers at scale with bots and advanced functions, such as message broadcast campaigns, links to websites, multiple logins, and many more. "
    },
    {
        title: "How to apply for WhatsApp Business API?",
        desciption: "WhatsApp Business API allows you to communicate with customers at scale with bots and advanced functions, such as message broadcast campaigns, links to websites, multiple logins, and many more. "
    },
    {
        title: "What's the difference between WhatsApp Business app and WhatsApp Business API?",
        desciption: "WhatsApp Business API allows you to communicate with customers at scale with bots and advanced functions, such as message broadcast campaigns, links to websites, multiple logins, and many more. "
    },
    
]

export const Whatsapp = () =>{
    const ref = useRef()

    useEffect(() =>{
        ref.current.scrollIntoView()

    }
    )

    return (
        <>
          <article ref={ref} className="container-fluid bg-texture bg">

        <div className="container">
            <div className="row pt-75">
                <div className="col-lg-5">
                    <div className="hero_aside">
                        <h6>Offical</h6>
                        <h2 className="hero_title">
                        WhatsApp<br></br>
                        Business API
                        </h2>
                        <p className="hero_content">
                        Simplify customer communication and more.
                        With a trustworthy business profile, you can easily
                        broadcast and manage communications.
                        </p>
                        <div className="frame">
                            <button className="new-button custom-newbtn btn-6"
                                    onClick={() => window.open("https://wa.me/message/DZRNNZUFUVCXM1", '_blank', 'noopener,noreferrer')}>
                                <span>Take a Demo &nbsp;&nbsp;<img src={Arrow} alt="" srcSet=""/></span></button>
                        </div>

                    </div>
                </div>
                <div className="col-lg-7 side-hero justify-content-center">
                    <img src={Hero} alt="" className="whatsapp-slide"/>
                </div>
            </div>
        </div>
    </article>
    <section class="container">
      <div class="row services">
        <div class="col-lg-12">
        <p class="high_text text-center">BENEFITS</p>
          <h3 class="our_head">Why WhatsApp Business<br></br>
API with BotsEdge?</h3>
        
          <p class="custom_text mb-0">
          Customers want to connect with you using their<br></br>
favorite communication platforms 
          </p>
        </div>
        <MultiTab />
        
      </div>

    </section>

    <section className="container">
      <div className="row ptb-50">
        <div className="col-lg-6">
        <h3 class=" text-left high_text">BENEFITS</h3>
          <h2 class="herotitle">
          Explore WhatsApp<br />
Integration with 
            
            <span class="business_title"> BotsEdge?</span>
          </h2>
          <p class="hero_content">
          Automate customer support, notifications, and promotional messages for your business using powerful triggers and actions. Migrate your account seamlessly with no downtime.
          </p>
          <div class="frame text-left"><button class="new-button custom-newbtn btn-6"><span>Connect Your Account &nbsp;&nbsp;<img src={Arrow} alt="" srcSet=""/></span></button></div>
        </div>
        <div className="col-lg-6 align-img ">
          <img src={sideimg} alt="" srcSet=""  />
        </div>
      </div>
    </section>
   

    <FAQCard faqItems={faqItems}/>
<section className="wats-oofcial-out">
    <div className="container">
<h3 className="our_head">Connect your WhatsApp official<br></br> business account to
<br></br>BotsEdge Today</h3>

<div class="frame text-center"><button class="new-button custom-newbtn btn-6"><span>Connect Your Account &nbsp;&nbsp;<img src={Arrow} alt="" srcSet=""/></span></button></div>
    </div>

</section>


        </>
    );

}



export const FAQCard = ({faqItems}) =>{
    const [active, setActive] = useState(0)

    return <>
    <section className="container">
        <div className="row">

        <div class="col-lg-12 text-center">
        <p class="high_text text-center">faq</p>
          <h3 class="our_head">Frequently Asked Questions
</h3>
        
          <p class="custom_text mb-0">
          We’re Happy to Answer your Questions!
          </p>
        </div>
            <div className="col-md-12 tabs-main">


            {
        faqItems.map((item, index) => <div className={"faq-tab " + (active === index ? 'faq-active': '')} key={index}>
        <div className="faq-title" onClick={() => setActive(index)}>
        <h3 className="mb-0">{item.title}</h3>
        <img src={active === index? close: open} alt="" srcSet="" className="open-btn" onClick={() => setActive(index)}/>
        </div>
        {active === index && <div className="faq-cont">
        <p className="mb-0">{item.desciption}</p>
        </div>}
        </div>)
    }

            </div>

        </div>
    </section>
    
    
    </>
    
}

const tabs = [
    {
        title: "Multiple logins",
        desciption: "Reply to customers simultaneously collaborative effort equals efficiency. Salespersons, customer service, and more will no longer need to create a group chat for every customer, as team members in different locations and timezones can access the same chatbox and co-own the conversation."
    },
    {
        title: "Broadcast WhatsApp campaign",
        desciption: "Reply  Salespersons, customer service, and more will no longer need to create a group chat for every customer, as team members in different locations and timezones can access the same chatbox and co-own the conversation."
    },
    {
        title: "WhatsApp chat buttons",
        desciption: "Reply to customers simultaneously collaborative effort equals efficiency. Salespersons, ation."
    },
    {
        title: "Business Solution Provider",
        desciption: "Reply to customer service, and more will no longer need to create a group chat for every customer, as team members in different locations and timezones can access the same chatbox and co-own the conversation."
    }
]


const MultiTab  = () =>{

    const [selectedIndex, setSelectedIndex] = useState(0);


    return <div className="row align-items-center tabs-main">
    <div className="col-md-5">
        <ul className="tab-list">
            {tabs.map((tab, index) =>  <li className={selectedIndex === index ? "active": undefined} 
            onClick={() => setSelectedIndex(index)}
            key={index}>{tab.title}</li> )}
        
        </ul>
    </div>
    <div className="col-md-7 ">
<div className="tab-content">
<p>{tabs[selectedIndex].desciption}</p>
</div>
    </div>


</div>
}
