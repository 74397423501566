import React from "react";
import "./blog.css";
import blog1 from "../../assets/blog-01.png";
import blog2 from "../../assets/blog-02.png";
import blog3 from "../../assets/blog-03.png";
import Arrow from "../../assets/blue-vector.svg";
import {Link} from 'react-router-dom'

const BlogHome = () => {
  return (
    <section className="blog-home ptb-50">
        <div className="container">
   
      <div className="row py-2 mb-4 align-items-center">
        <div className="col-md-6 text-left">
            <h2 class="our_head text-left mb-0">Recent Blogs</h2>
        </div>
        <div className="col-md-6 text-right">
            <button>View All&nbsp;&nbsp;<img src={Arrow} alt="" srcSet=""/></button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
            <div className="blog-item">
              <img src={blog2} alt="" srcSet=""/>
              <div className="blog-item-txt">
              <Link to='/'><h4>Pretium donec placerat volutpat velit leo ristique.</h4></Link>
              <Link to='/'>Read More &nbsp;&nbsp;<img src={Arrow} alt="" srcSet=""/></Link>
              </div>
            </div>
        </div>
        <div className="col-md-4">
            <div className="blog-item">
              <img src={blog3} alt="" srcSet=""/>
              <div className="blog-item-txt">
              <Link to='/'><h4>Pretium donec placerat volutpat velit leo ristique.</h4></Link>
              <Link to='/'>Read More &nbsp;&nbsp;<img src={Arrow} alt="" srcSet=""/></Link>
              </div>
            </div>
        </div>
        <div className="col-md-4">
            <div className="blog-item">
              <img src={blog1} alt="" srcSet=""/>
              <div className="blog-item-txt">
              <Link to='/'><h4>Pretium donec placerat volutpat velit leo ristique.</h4></Link>
              <Link to='/'>Read More &nbsp;&nbsp;<img src={Arrow} alt="" srcSet=""/></Link>
              </div>
            </div>
        </div>
      </div>
    </div>

  </section>
  );
};

export default BlogHome;
