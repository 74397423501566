import React from "react";
import "./services.css";
import Whatsapp from "../../assets/whatsapp.svg";
import Telegram from "../../assets/telegram.svg";
import Messenger from "../../assets/messenger.svg";
import Bot from "../../assets/chatbot.svg";
import Arrow from "../../assets/blue-vector.svg";
import {Link} from 'react-router-dom'

const services = [
  {
    title: "WhatsApp",
    description: "Make a catalogue to display your goods and services. Utilize tools to automate, sort, and quickly respond to messages to connect with your customers. ",
    img: Whatsapp,
    link: "whatsapp#"
  },
  {
    title: "Telegram",
    description: "With a focus on security and speed, Telegram is a cloud-based messaging app for ",
    img: Telegram,
    link: "telegram"
  },
  {
    title: "Messenger",
    description: "Keeping in touch with your users is simple and enjoyable with Messenger. ",
    img: Messenger,
    link: "messenger"
  },
  {
    title: "Chatbots",
    description: "With entertaining AR effects, message effects, and selfie stickers, you can liven up your conversations. ",
    img: Bot,
    link: "chatbots"
  },
  
]


const Services = () => {
  return (
    <section class="container">
      <div class="row services">
        <div class="col-lg-12 text-center">
        <p class="high_text text-center">Our Services</p>
          <h3 class="our_head"> Keep Conversations going <br></br>
            across Platforms{" "}</h3>
        
          <p class="custom_text">
            Customers want to connect with you using their <br />
            favorite communication platforms{" "}
          </p>
        </div>
      </div>
      <div class="row">
        {services.map(service =>{
          return <div class="col-lg-3 d-flex justify-content-center">
          <div class="service_box">
            <div className="text-left">
              <img src={service.img} alt="" srcset="" width="60" class="mb-3" />
            </div>
            <div>
              <h4 class="serv_hed"> {service.title} X BotsEdge</h4>
            </div>
            <div>
              <p class="serv_cont">{service.description}
              </p>
              <p class="know_more">
              <Link to={service.link}>Know More &nbsp;&nbsp;<img src={Arrow} alt="" srcSet=""/></Link>
              </p>
            </div>
          </div>
        </div>
        })}
      </div>
      <div class="frame text-center"><button class="new-button custom-newbtn btn-6"><span>Explore All &nbsp;&nbsp;<img src={Arrow} alt="" srcSet=""/></span></button></div>
      
    </section>
  );
};

export default Services;
