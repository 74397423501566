import React from "react";
import "./nocode.css";
import sideimg from "../../assets/Chatbot-bro.svg";
const NoCode = () => {
  return (
    <main className="bot-build">
    <section className="container">
      <div className="row ptb-50">
        <div className="col-lg-6">
        <h3 class=" text-left high_text">Bot Builder</h3>
          <h2 class="herotitle">
            Create Your Own Conversational
            <br />
            <span class="business_title"> Bots Easily</span>
          </h2>
          <p class="hero_content">
            Create an intelligent, personalised conversational bot without
            writing a single line of code. Create custom workflows using the
            Drag-and-Drop chatbot builder platform without any technical
            assistance.
          </p>
          
        </div>
        <div className="col-lg-6 align-img">
          <img src={sideimg} alt="" srcSet="" width="400" />
        </div>
      </div>
    </section>
    </main>
  );
};

export default NoCode;
