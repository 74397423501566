import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {RouterProvider, createBrowserRouter, Outlet} from "react-router-dom";
import {LandingPage} from "./components/landing/landing";
import {Service} from "./components/service/service";
import {Blog} from "./components/blog/blog";
import {Whatsapp} from "./components/whats-app/whatsapp";
import {Messenger} from "./components/messenger/messenger";
import {Telegram} from "./components/telegram/telegram";
import {Chatbots} from "./components/chatbots/chatbots";
import {Aboutus} from "./components/aboutus/aboutus";
import {Contactus} from "./components/contactus/contactus";

const router = createBrowserRouter([

    {
        path: "/",
        element: <App />,
        children:[
            {
                index: true,
                element: <LandingPage />
            },
            {
                path: "service",
                element: <Service/>
            },
            {
                path: "blog",
                    element: <Blog/>
            },
            {
                path: "whatsapp",
                    element: <Whatsapp/>
            },
            {
                path: "telegram",
                    element: <Telegram/>
            },
            {
                path: "messenger",
                    element: <Messenger/>
            },
            {
                path: "chatbots",
                    element: <Chatbots/>
            },
            {
                path: "Aboutus",
                    element: <Aboutus/>
            },
            {
                path: "Contactus",
                    element: <Contactus/>
            },
         
        ]
    }
])


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/*<App />*/}
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
