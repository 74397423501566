import "./messenger.css";
import Arrow from "../../assets/arrow.svg";
import Heroo from "../../assets/Facebook_Header_img.png";
import how from "../../assets/Automated_Comments_Fb.png";
import howw from "../../assets/FB-Post_Mockup.png";
import{
  FAQCard  
} from "../whats-app/whatsapp";
import { useRef, useEffect } from "react";

const faqItems = [
  {
      title: "Is there a business version of Facebook?",
      desciption: "You may create a Facebook Business Page using a personal Facebook account or convert a personal Facebook Page to a Facebook Business Page. Facebook also offers a business inbox in Messenger to help businesses respond to customer messages and toggle between their personal and business accounts in the Messenger app. "
  },
  {
      title: "How do I get Facebook Messenger for my business?",
      desciption: "Facebook Business API allows you to communicate with customers at scale with bots and advanced functions, such as message broadcast campaigns, links to websites, multiple logins, and many more. "
  },
  {
      title: "What do I need to connect Facebook Business account to BotsEdge?",
      desciption: "Facebook Business API allows you to communicate with customers at scale with bots and advanced functions, such as message broadcast campaigns, links to websites, multiple logins, and many more. "
  },
  {
      title: "Can I sync conversation history with BotsEdge?",
      desciption: "Facebook Business API allows you to communicate with customers at scale with bots and advanced functions, such as message broadcast campaigns, links to websites, multiple logins, and many more. "
  },
  
]

export const Messenger = () =>{
    const ref = useRef()

    useEffect(() =>{
        ref.current.scrollIntoView()

    }
    )

    return (
        <>
          <article ref={ref} className="container-fluid bg-texture bg">

        <div className="container">
            <div className="row pt-75">
                <div className="col-lg-5">
                    <div className="hero_aside">
                        <h6>Offical</h6>
                        <h2 className="hero_title">
                        Facebook solutions<br></br> for businesses
                        </h2>
                        <p className="hero_content">
                        Reach billions of Facebook users and boost sales through instant comment and chat replies on posts, stories, and live streams. Employ smart tools to amuse customers through personalized interactions even without an extra ad budget.
                        </p>
                        <div className="frame">
                            <button className="new-button custom-newbtn btn-6"
                                    onClick={() => window.open("https://wa.me/message/DZRNNZUFUVCXM1", '_blank', 'noopener,noreferrer')}>
                                <span>Take a Demo &nbsp;&nbsp;<img src={Arrow} alt="" srcSet=""/></span></button>
                        </div>

                    </div>
                </div>
                <div className="col-lg-7 side-hero side-heroo justify-content-center">
                    <img src={Heroo} alt="" className="whatsapp-slide"/>
                </div>
            </div>
        </div>
    </article>
    <section class="container">
      <div class="row services">
        <div class="col-lg-12 text-center">
        <p class="high_text text-center">BENEFITS</p>
          <h3 class="our_head">Why Facebook with<br></br> BotsEdge?</h3>
        </div>
      </div>

    </section>
    <section class="how_section">
      <div class="container">
        <div class="row ptb-50 pt-3">
        <div class="col-lg-6 d-flex justify-content-start">
            <img src={how} alt="" srcSet="" className="step-img" />
          </div>
            <div class="col-lg-6">
            <h3 class=" text-left high_text">AUTOMATION</h3>
            <h2 class="how_title2">
            Automated comment
  <br></br> &  <span class="business_title">chat replies</span> 
            </h2>
            <p class="how_content">
            Boost organic engagement with your customers<br></br> with an automated workflow for Facebook <br></br>comments and messages across posts, stories, and<br></br> live streams. Tailor intelligent workflows based on<br></br> scenarios for your Facebook page to go viral.
            </p>
            
            <aside className="d-flex justify-content-center">

            
            <div class="frame how_btn mt-2">
<button class="new-button custom-newbtn btn-6"><span>Connect Your Account &nbsp;&nbsp;<img src={Arrow} alt="" srcSet=""/></span></button>
</div>
</aside>
            {/* <button type="submit" class="how_btn">
              Know More
            </button> */}
          </div>
           
        </div>
      </div>
    </section>
    <section class="how_section">
      <div class="container">
        <div class="row ptb-50">
       
            <div class="col-lg-6 pt-4">
            <h3 class=" text-left high_text">SOCIAL CRM</h3>
            <h2 class="how_title2">
            Turn Facebook <br></br>conversation into<br></br> customer loyalty
            </h2>
            <p class="how_content">
            Integrate with Facebook Lead ads to retrieve<br></br> contact information. Learn the customer behaviour<br></br> with analytics to outline a strategy that fits the<br></br> most.
            </p>
            
            <aside className="d-flex justify-content-center">

            
            
</aside>
            {/* <button type="submit" class="how_btn">
              Know More
            </button> */}
          </div>
          <div class="col-lg-6 d-flex justify-content-first pl-4">
            <img src={howw} alt="" srcSet="" className="step-img" />
          </div>
        </div>
      </div>
    </section>
    <section class="container">
      <div class="row services pt-5">
        <div class="col-lg-12 text-center">
          <h3 class="our_head">Why integrate Facebook<br></br> with BotsEdge?</h3>
        </div>
      </div>
<div className="row">
<div className="col-md-4">
        <div className="integrate-cnt">
          <h4>Increase brand<br></br> awareness without paying ads</h4>
          <p>Establish a presence for your business on the most popular social network by starting with a conversation, be it a comment or a message. Attract new customers and grow your client base organically.</p>
        </div>
</div>
<div className="col-md-4">
        <div className="integrate-cnt">
          <h4>Broadcast personalized<br></br> messages on Facebook<br></br> Messenger</h4>
          <p>Send bulk messages on Messenger in one click. Add parameters to personalize messages and make your customers feel like talking to a friend who knows them.</p>
        </div>
</div>
<div className="col-md-4">
        <div className="integrate-cnt">
          <h4>Interaction with loyal<br></br> and prospective<br></br> customers.</h4>
          <p>Building personal relationships with marketers. Increase engagement - to love, share, comment, view or follow videos and be organic in scope.</p>
        </div>
</div>
</div>
    </section>
    <section className="connet-outer">
      <div className="container">
        <div className="row">
            <div className="col-md-5">
              <div>
              <h3>Connect your<br></br>
Facebook account<br></br>
to BotsEdge</h3>
<div class="frame how_btn mt-4">
<button class="new-button custom-newbtn btn-6"><span>Connect Now &nbsp;&nbsp;<img src={Arrow} alt="" srcSet=""/></span></button>
</div>
              </div>
            </div>
            <div className="col-md-7">
              <ul>
                <li><span>1</span>View and reply to messages and comments in your team inbox</li>
                <li><span>2</span>Retrieve contacts and send messages just in time</li>
                <li><span>3</span>Send automated direct messages with pre-set rules</li>
              </ul>
            </div>
        </div>
      </div>
    </section>
    <FAQCard faqItems={faqItems}/>
        </>
    );

}
