import Arrow from "../../assets/arrow.svg";
import Hero from "../../assets/hero2.png";


export const Herosection = () =>{

    return <article className="container-fluid bg-texture bg">

        <div className="container">
            <div className="row pt-75">
                <div className="col-lg-5">
                    <div className="hero_aside">
                        <h2 className="hero_title">
                            PowerUp<br></br>

                            your <span className="business_title">Business </span><br></br>

                            with BotsEdge
                        </h2>
                        <p className="hero_content">
                            Use WhatsApp Business API to Enhance Customer Interaction
                        </p>
                        <div className="frame">
                            <button className="new-button custom-newbtn btn-6"
                                    onClick={() => window.open("https://wa.me/message/DZRNNZUFUVCXM1", '_blank', 'noopener,noreferrer')}>
                                <span>Try it Now &nbsp;&nbsp;<img src={Arrow} alt="" srcSet=""/></span></button>
                        </div>

                    </div>
                </div>
                <div className="col-lg-7 side-hero justify-content-right">
                    <img src={Hero} alt="" className="hero-img" width="615"/>
                </div>
            </div>
        </div>
    </article>

}