import React from "react";
import "./header.css";
import Logo from "../../assets/BotsEdge-logo.png";
import Arrow from "../../assets/arrow.svg";
import DownArrow from "../../assets/down-arrow.svg";
import Hero from "../../assets/hero2.png";
import {Link} from 'react-router-dom'


const Header = () => {
  // const [open, setOpen] = React.useState(false);
 
  return (
      <>
      <nav className="mb-nav">
      <div class="navbar-mb">
        <div class="container-navmb nav-container-mb">
            <input class="checkbox" type="checkbox" name="" id="" />
              <div class="logo-mb">
             <img src={Logo} alt="" className="img-logo"/>
          </div>
            <div class="hamburger-lines">
              <span class="line line1"></span>
              <span class="line line2"></span>
              <span class="line line3"></span>
            </div>  
        
          <div class="menu-items">
            <li>Home</li>
            <li>about</li>
            <li>blogs</li>
            <li>portfolio</li>
            <li>contact</li>
          </div>
        </div>
      </div>
    </nav>
    <section className="hero-section desk-nav">
    
      <div className="header">
        <div className="container">
       <div className="row">
       
<div className="col-md-4">
        <Link to='/'>

<div className="logo">
    <img src={Logo} alt=""/>
</div>
</Link>
</div>
      <nav className="header-nav col-md-8">
 

        <div class="hamburger">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line3"></div>
        </div>
        <ul className="nav-links">
            <li><Link to='/'>Home</Link></li>
            <li><Link to='service'>Services <img src={DownArrow} alt=""/></Link></li>
            <li><Link to='blog'>Blog</Link></li>

            <li><Link to='aboutus'>About Us</Link></li>
            <li><Link to='contactus'>Contact</Link></li>
            {/* <li><button class="login-button" href="#">Login</button></li>
            <li><button class="join-button" href="#">Join</button></li> */}
        </ul>
    </nav>
        
        </div>
       </div>
        
      </div>

    </section>
    </>
  );
};

export default Header;
