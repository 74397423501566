import React from "react";
import "./steps.css";
import how from "../../assets/howwe.png";

const Steps = () => {
  return (
    <section class="how_section">
      <div class="container">
        <div class="row ptb-50">
        <div class="col-lg-6 d-flex justify-content-center">
            <img src={how} alt="" srcSet="" width="600" className="step-img"/>
          </div>
            <div class="col-lg-6">
            <h3 class=" text-left high_text">How - we - do</h3>
            <h2 class="how_title2">
              Why Do I Need a  <span class="business_title">Chatbot ?</span> 
            </h2>
            <p class="how_content">
              Resolve customer issues at any time and from any location. You
              don't have to limit yourself to office hours to provide a great
              and consistent experience.
            </p>
            <ul class="how_order">
              <li>Free up Service Agents' Time</li>
              <p>
                Your agents will save time by using automated conversations to
                assist customers with difficult and complex questions.
              </p>

              <li>Know Your Customers</li>
              <p>
                You can improve if you understand your customers. You will gain
                valuable insights from our AI-powered chatbot to continuously
                improve your service and grow your business.
              </p>
            </ul>
            <aside className="d-flex justify-content-center">

            
            <div class="frame how_btn mt-2">
<button class="new-button custom-newbtn btn-6"><span>Know More</span></button>
</div>
</aside>
            {/* <button type="submit" class="how_btn">
              Know More
            </button> */}
          </div>
           
        </div>
      </div>
    </section>
  );
};
export default Steps;
