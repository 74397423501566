import "./telegram.css";
import Arrow from "../../assets/arrow.svg";
import Heroo from "../../assets/Facebook_Header_img.png";
import how from "../../assets/telegram-chatbot.png";
import hown from "../../assets/Whatsapp_Integratio.png";
import howw from "../../assets/payments-accept.png";
import{
  FAQCard  
} from "../whats-app/whatsapp";
import { useRef, useEffect } from "react";

const faqItems = [
  {
      title: "What is a Telegram chatbot?",
      desciption: "It is a helping hand for any business and marketer. Its goal is to provide users with flexible responses to their questions via Telegram. The more requests your chatbot can handle, the more lively and effective your conversation will be. "
  },
  {
      title: "Why should I create a chatbot?",
      desciption: "Telegram Business API allows you to communicate with customers at scale with bots and advanced functions, such as message broadcast campaigns, links to websites, multiple logins, and many more. "
  },
  {
      title: "How does a Telegram chatbot work?",
      desciption: "Telegram Business API allows you to communicate with customers at scale with bots and advanced functions, such as message broadcast campaigns, links to websites, multiple logins, and many more. "
  },
  {
      title: "How can I create a Telegram chatbot?",
      desciption: "Telegram Business API allows you to communicate with customers at scale with bots and advanced functions, such as message broadcast campaigns, links to websites, multiple logins, and many more. "
  },
  
]




export const Telegram = () =>{
    const ref = useRef()

    useEffect(() =>{
        ref.current.scrollIntoView()

    }
    )

    return (
        <>
          <article ref={ref} className="container-fluid bg-texture bg">

        <div className="container">
            <div className="row pt-75">
                <div className="col-lg-5">
                    <div className="hero_aside">
                        <h6>Offical</h6>
                        <h2 className="hero_title">
                        Chatbot for<br></br> Telegram
                        </h2>
                        <p className="hero_content">Set up auto-replies, establish promotional campaigns, and automate your business procedures.</p>
                        <div className="frame">
                            <button className="new-button custom-newbtn btn-6"
                                    onClick={() => window.open("https://wa.me/message/DZRNNZUFUVCXM1", '_blank', 'noopener,noreferrer')}>
                                <span>Take a Demo &nbsp;&nbsp;<img src={Arrow} alt="" srcSet=""/></span></button>
                        </div>

                    </div>
                </div>
                <div className="col-lg-7 side-hero justify-content-center">
                    <img src={Heroo} alt="" className="whatsapp-slide"/>
                </div>
            </div>
        </div>
    </article>
    <section class="container">
      <div class="row services">
        <div class="col-lg-12 text-center">
        <p class="high_text text-center">BENEFITS</p>
          <h3 class="our_head">Now you’re Ready! Your Telegram bot is<br></br> connected. It’s time to set up auto-replies.</h3>
        
          
        </div>
      </div>

    </section>
    <section class="how_section">
      <div class="container">
        <div class="row ptb-50 pt-3">
        <div class="col-lg-6 d-flex justify-content-start">
            <img src={how} alt="" srcSet="" className="step-img" width="547"/>
          </div>
            <div class="col-lg-6">
           
            <h2 class="how_title2">
            Visual Chatbot<br></br> Message Builder
            </h2>
            <p class="how_content">
            To inform, counsel, and even sell goods and services, create Telegram bots. Enter the keywords that will cause and send your auto-replies to begin a conversation flow.
            </p>
            
            <aside className="d-flex justify-content-center">

            
            <div class="frame how_btn mt-2">
<button class="new-button custom-newbtn btn-6"><span>Connect Your Account</span></button>
</div>
</aside>
            {/* <button type="submit" class="how_btn">
              Know More
            </button> */}
          </div>
           
        </div>
      </div>
    </section>
    <section class="how_section">
      <div class="container">
        <div class="row ptb-50 pt-2">
       
            <div class="col-lg-6 pt-4">
            <h2 class="how_title2">
            Send Bulk Messages<br></br> to Your Telegram<br></br> Subscribers
            </h2>
            <p class="how_content">
            Add text, images, files, and buttons to your message. When the message is ready, you can schedule it to send on a specific day and time. After sending your campaign check its statistics. The system shows the number of sent and read messages as well as the number of links opened from your message.

            </p>
            
            <aside className="d-flex justify-content-center">

            
            
</aside>
            {/* <button type="submit" class="how_btn">
              Know More
            </button> */}
          </div>
          <div class="col-lg-6 d-flex justify-content-first justify-content-right">
            <img src={hown} alt="" srcSet="" className="step-img" />
          </div>
        </div>
      </div>
    </section>
    <section class="how_section">
      <div class="container">
        <div class="row ptb-50 pt-3">
        <div class="col-lg-6 d-flex justify-content-start">
            <img src={howw} alt="" srcSet="" className="step-img" width="547"/>
          </div>
            <div class="col-lg-6">
           
            <h2 class="how_title2">
            Accept payments
            </h2>
            <p class="how_content">
            Let your chatbot become an effective sales channel for your business. Enable seamless online payments via chatbots for your customers by integrating payment systems to your chatbot and adding payment buttons to its flows.

            </p>
            
            <aside className="d-flex justify-content-center">

            
            <div class="frame how_btn mt-2">
<button class="new-button custom-newbtn btn-6"><span>Connect Your Account</span></button>
</div>
</aside>
            {/* <button type="submit" class="how_btn">
              Know More
            </button> */}
          </div>
           
        </div>
      </div>
    </section>
    <FAQCard faqItems={faqItems}/>
    
        </>
    );

}


