import Services from "../services/services";
import Steps from "../steps/steps";
import NoCode from "../nocode/nocode";
import TestMonials from "../testimonials/testimonials";
import BlogHome from "../blog-home/blog";
import Contact from "../contact/contact";
import {Herosection} from "../herosection/herosection";


export const LandingPage = () =>{
    return (
        <>
            <Herosection/>
            <Services />
            <Steps />
            <NoCode />
            <TestMonials />
            <BlogHome />
            <Contact />
        </>
    );
}

