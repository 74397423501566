import React from "react";
import "./testimonials.css";
import avathar from "../../assets/Ellipse-8.png";
import avathar9 from "../../assets/Ellipse-9.png";
import avathar10 from "../../assets/Ellipse-10.png";
import quotes from "../../assets/Ellipse-quote.png";
const TestMonials = () => {
  return (
    <section className="testimonial-out">
        <div className="container">
    <div class="row text-center">
      <div class="col-lg-12">
      <p class="high_text text-center">testimonialss</p>
        <h3 class="our_head">What our Customers say<br></br> About Us</h3>
      </div>
      </div>

      <div className="row mt-4 px-5">
          <div className="col-md-4">
              <div className="testi-txt">
                    <p>Lorem ipsum dolor sit amet, dui consectetur adipiscing elit. Sed risus id sit id dolor dui, massa. Convallis arcu lorem id tellus volutpat, et. Morbi sed integer at enim sit odio mi egestas facilisis. Tempor etiam commodo in urus.</p>
                    <div className="auther-nm d-flex">
                    <img src={avathar} alt="" srcSet=""/>
                    <h5>Abram Saris</h5>
                    </div>
                    <img src={quotes} alt="" srcSet="" className="quotes-im"/>
              </div>
          </div>
          <div className="col-md-4">
              <div className="testi-txt">
                    <p>Lorem ipsum dolor sit amet, dui consectetur adipiscing elit. Sed risus id sit id dolor dui, massa. Convallis arcu lorem id tellus volutpat, et. Morbi sed integer at enim sit odio mi egestas facilisis. Tempor etiam commodo in urus.</p>
                    <div className="auther-nm d-flex">
                    <img src={avathar9} alt="" srcSet=""/>
                    <h5>Abram Saris</h5>
                    </div>
                    <img src={quotes} alt="" srcSet="" className="quotes-im"/>
              </div>
          </div>
          <div className="col-md-4">
              <div className="testi-txt">
                    <p>Lorem ipsum dolor sit amet, dui consectetur adipiscing elit. Sed risus id sit id dolor dui, massa. Convallis arcu lorem id tellus volutpat, et. Morbi sed integer at enim sit odio mi egestas facilisis. Tempor etiam commodo in urus.</p>
                    <div className="auther-nm d-flex">
                    <img src={avathar10} alt="" srcSet=""/>
                    <h5>Abram Saris</h5>
                    </div>
                    <img src={quotes} alt="" srcSet="" className="quotes-im"/>
              </div>
          </div>
      </div>
      
    </div>

  </section>
  );
};

export default TestMonials;
