import React from "react";
import email from "../../assets/email-bl.png";
import phone from "../../assets/phone-bl.png";
import "./contact.css";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    // <section className="container contact ptb-75">
    //   <h3 className="our_head">Contact Us</h3>
    //   <p className="high_text">
    //     So, What are you
    //     <span className="waiting"> Waiting for?</span>
    //   </p>
    //   <small className="small">
    //     Contact us to <strong>Set your ChatBot Today</strong>
    //   </small>
    //   <div className="container">
    //     <section className="contact-mid">
    //       <form>
    //         <div className="row mt-2rem">
    //           <div className="form-group col-md-6">
    //             <label for="inputEmail4" class="mb-2">
    //               Name
    //             </label>
    //             <input type="name" className="form-control" id="inputEmail4" />
    //           </div>
    //           <div className="form-group col-md-6">
    //             <label for="inputPassword4" class="mb-2">
    //               Your Mail
    //             </label>
    //             <input
    //               type="email"
    //               className="form-control"
    //               id="inputPassword4"
    //             />
    //           </div>
    //         </div>
    //         <div className="row"></div>
    //         <div className="form-group">
    //           <label class="mb-2 mt-4">Message</label>
    //           <textarea
    //             name=""
    //             id=""
    //             cols="30"
    //             rows="10"
    //             className="w-100 text-msg"
    //           ></textarea>
    //         </div>
    //         <div className="just_btn">
    //           <button type="submit" className="how_btn">
    //             Just Send&nbsp;&nbsp;
    //             <img src={Arrow} alt="" srcset="" />
    //           </button>
    //         </div>
    //       </form>
    //     </section>
    //   </div>
    // </section>
    <>
    {/* <div class="background">
  <div class="container-contact">
         <h3 className="our_head">Contact Us</h3>
      <p className="high_text text-center">
         So, What are you
         <span className="waiting"> Waiting for?</span>
       </p>
       <small className="small">
        Contact us to <strong>Set your ChatBot Today</strong>
       </small>
    <div class="screen">
      
      <div class="screen-body">
        <div class="screen-body-item left">
          <div class="app-title">
            <span>CONTACT US</span>
          </div>
        
        </div>
        <div class="screen-body-item">
          <div class="app-form">
            <div class="app-form-group">
               <input type={'text'} class="app-form-control" placeholder="NAME"/>
            </div>
            <div class="app-form-group">
              <input type={'email'} class="app-form-control" placeholder="EMAIL"/>
            </div>
            <div class="app-form-group">
              <input type={'number'} class="app-form-control" placeholder="CONTACT NO"/>
            </div>
            <div class="app-form-group message">
              <input type={'text'} class="app-form-control" placeholder="MESSAGE"/>
            </div>
            <div class="app-form-group buttons">
              
              <button class="app-form-button">SEND</button>
            </div>
          </div>
        </div>
      </div>
    </div>
   
  </div>
</div> */}


<section className="contact-home">
  <div className="container">
      <div className="row"> 
              <div className="col-md-5">

                <div className="cont-details text-left">
                  <h2 className="our_head">So, What are you<br></br>
<span className="business_title">Waiting for ?</span></h2>
<p>Contact us to Set your chatbot Ready</p>
<ul className="con-dtl">
  <li><img src={phone} alt="" srcSet=""/> <Link to='tel:+91 9951994005'>+91 9951994005</Link></li>
  <li><img src={email} alt="" srcSet=""/> <Link to='mailto:ram@botsedge.com'>ram@botsedge.com</Link></li>
</ul>
                </div>

              </div>
      </div>
  </div>
</section>
</>
  );
};

export default Contact;
