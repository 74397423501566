import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
// React Router
import {Outlet} from 'react-router-dom';
import Header from "./components/header/header";
import Footer from "./components/footer/footer";


function App(){
  return (
    <div className="Index">
        <Header />
        <Outlet />
        <Footer />
      </div>
  );
}

export default App;
