import "./chatbots.css";
import Arrow from "../../assets/arrow.svg";
import Heroo from "../../assets/Facebook_Header_img.png";
import { useRef, useEffect } from "react";
import hown from "../../assets/Whatsapp_Integratio.png";


export const Chatbots = () =>{
    const ref = useRef()

    useEffect(() =>{
        ref.current.scrollIntoView()

    }
    )

    return (
        <>
          <article ref={ref} className="container-fluid bg-texture bg">

        <div className="container">
            <div className="row pt-75">
                <div className="col-lg-5">
                    <div className="hero_aside">
                        <h6>Offical</h6>
                        <h2 className="hero_title">
                        Website Chatbot<br></br> Platform
                        </h2>
                        <p className="hero_content">
                        Automate customer support, improve marketing activities, and generate leads with customized website chatbot. Let the AI-driven website chatbot solve routine and common queries.
                        </p>
                        <div className="frame">
                            <button className="new-button custom-newbtn btn-6"
                                    onClick={() => window.open("https://wa.me/message/DZRNNZUFUVCXM1", '_blank', 'noopener,noreferrer')}>
                                <span>Take a Demo &nbsp;&nbsp;<img src={Arrow} alt="" srcSet=""/></span></button>
                        </div>

                    </div>
                </div>
                <div className="col-lg-7 side-hero justify-content-center">
                    <img src={Heroo} alt="" className="whatsapp-slide"/>
                </div>
            </div>
        </div>
    </article>
    <section class="container">
      <div class="row services">
        <div class="col-lg-12 text-center">
        <p class="high_text text-center">BENEFITS</p>
          <h3 class="our_head">A powerful, all-in-one customer service tool</h3>
        
          <p class="custom_text">
          One compact solution can delight your customers and fuel your sales.
          </p>
        </div>
      </div>

    </section>

    <section class="how_section">
      <div class="container">
        <div class="row ptb-50 pt-2">
       
            <div class="col-lg-6 pt-4">
            <h2 class="how_title2">Live Chat</h2>
            <p class="how_content"> Half of all online customers prefer live chat over any <br></br>other communication channel. </p>
            <ul className="lists-ot">
            <li>Connect BotsEdge to ALL websites and platforms via <br></br>dedicated plugins, without coding</li>
            <li>The BotsEdge live chat widget adjusts to your customers’ screens, so they can write to you on mobile and tablet</li>
            <li>Our live chat widget is customizable: you can change its placement and color, so it fits with your website aesthetics</li>
            <li>You can adjust the positioning of the live chat button in a mobile version of the widget.</li>
            </ul>
            
        
          </div>
          <div class="col-lg-6 d-flex justify-content-first justify-content-center">
            <img src={hown} alt="" srcSet="" className="step-img" height={400} />
          </div>
        </div>
      </div>
    </section>

    <section class="how_section">
      <div class="container">
        <div class="row ptb-50 pt-5">
        <div class="col-lg-6 d-flex justify-content-first justify-content-center">
            <img src={hown} alt="" srcSet="" className="step-img" height={390}/>
          </div>
            <div class="col-lg-6">
            <h2 class="how_title2">Your virtua AI<br></br>
supporter</h2>
            <p class="how_content"> Use one of our 20+ templates or easily build your own chatbot flow to automate 40% of your customer service conversations. </p>
            <ul className="lists-ot">
            
                <li>We offer 20+ chatbot templates that will answer questions about orders, shipping, and discounts</li>
                <li>If we don’t have a template that fits your needs, you can build your own conversation scenario without coding, in an intuitive drag-and-drop visual editor</li>
                <li>Let chatbots finish conversations and handle simple tasks to decrease your customer support workload</li>
                
                

            </ul>
            
        
          </div>
   
        </div>
      </div>
    </section>

    <section class="how_section">
      <div class="container">
        <div class="row ptb-50 pt-2">
       
            <div class="col-lg-6 pt-4">
            <h2 class="how_title2">Generate more sales</h2>
            <p class="how_content"> Did you know that 3M businesses generated 20M leads and $400M in sales after they started using our chatbots? </p>
            <ul className="lists-ot">
            <li>Stop 60% of your clients from abandoning their carts <br></br>by offering a discount</li>
            <li>Automatically qualify leads and reach out to them at <br></br>the right time</li>
            <li>Easily gather your visitors’ emails and phone numbers</li>
            </ul>
            
        
          </div>
          <div class="col-lg-6 d-flex justify-content-first justify-content-center">
            <img src={hown} alt="" srcSet="" className="step-img" height={400} />
          </div>
        </div>
      </div>
    </section>

    <section class="how_section">
      <div class="container">
        <div class="row ptb-50 pt-5">
        <div class="col-lg-6 d-flex justify-content-first justify-content-center">
            <img src={hown} alt="" srcSet="" className="step-img" height={420}/>
          </div>
            <div class="col-lg-6">
            <h2 class="how_title2">Answer any channel<br></br>from one place</h2>
            <p class="how_content"> As many as 75% of online customers said they expect help within five minutes. Connect all your channels to Tidio and answer your customers in an instant!</p>
            <ul className="lists-ot">
            
                <li>Integrate your live chat, email inbox, Messenger, and<br></br> Instagram into one multichannel communicator</li>
                <li>Talk to your customers on all devices: desktop, mobile,<br></br> and tablet</li>
                <li>Never lose a potential customer because they<br></br> contacted you on a channel you rarely use</li>
                
                

            </ul>
            
        
          </div>
   
        </div>
      </div>
    </section>


    <section class="how_section">
      <div class="container">
        <div class="row ptb-50 pt-2">
       
            <div class="col-lg-6 pt-4">
            <h2 class="how_title2">Empower your<br></br>customer support team</h2>
            <p class="how_content"> Customer support automation saves businesses approximately $8 billion annually.</p>
            <ul className="lists-ot">
            <li>Answer the most important queries right away thanks<br></br> to views with assigned and unassigned messages</li>
            <li>Monitor the performance of your operators thanks to<br></br> advanced analytics</li>
            <li>Answer repetitive questions faster with quick reply<br></br> templates</li>
            </ul>
            
        
          </div>
          <div class="col-lg-6 d-flex justify-content-first justify-content-center">
            <img src={hown} alt="" srcSet="" className="step-img"  />
          </div>
        </div>
      </div>
    </section>
    <section className="wats-oofcial-out">
    <div className="container">
<h3 className="our_head text-center">Connect <span class="business_title">BotsEdge</span> to your<br></br>
website Today</h3>

<div class="frame text-center"><button class="new-button custom-newbtn btn-6"><span>Connect Now &nbsp;&nbsp;<img src={Arrow} alt="" srcSet=""/></span></button></div>
    </div>

</section>
        </>
    );

}
