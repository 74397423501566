import "./service.css";
import Arrow from "../../assets/arrow.svg";
import Hero from "../../assets/whatsapp-slide.png";
import { useRef, useEffect } from "react";



export const Service = () =>{
    const ref = useRef()

    useEffect(() =>{
        ref.current.scrollIntoView()

    }
    )

    return (
        <>
          <article ref={ref} className="container-fluid bg-texture bg">

        <div className="container">
            <div className="row pt-75">
                <div className="col-lg-5">
                    <div className="hero_aside">
                        <h6>Offical</h6>
                        <h2 className="hero_title">
                        WhatsApp<br></br>
                        Business API
                        </h2>
                        <p className="hero_content">
                        Simplify customer communication and more.
                        With a trustworthy business profile, you can easily
                        broadcast and manage communications.
                        </p>
                        <div className="frame">
                            <button className="new-button custom-newbtn btn-6"
                                    onClick={() => window.open("https://wa.me/message/DZRNNZUFUVCXM1", '_blank', 'noopener,noreferrer')}>
                                <span>Take a Demo &nbsp;&nbsp;<img src={Arrow} alt="" srcSet=""/></span></button>
                        </div>

                    </div>
                </div>
                <div className="col-lg-7 side-hero justify-content-center">
                    <img src={Hero} alt="" className="whatsapp-slide"/>
                </div>
            </div>
        </div>
    </article>
    <section class="container">
      <div class="row services">
        <div class="col-lg-12 text-center">
        <p class="high_text text-center">BENEFITS</p>
          <h3 class="our_head">Why WhatsApp Business<br></br>
API with BotsEdge?</h3>
        
          <p class="custom_text">
          Customers want to connect with you using their<br></br>
favorite communication platforms 
          </p>
        </div>
      </div>

    </section>
        </>
    );

}
