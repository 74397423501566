import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/BotsEdge-logo.png";
import map from "../../assets/map.png";
import phone from "../../assets/phone.png";
import email from "../../assets/email.png";



const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="container">
        {/* <div className="footer-cta pt-5 pb-5">
          <div className="row">
            <div className="col-xl-4 col-md-4 mb-30">
              <div className="location">
                <div>
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/684/684908.png"
                    alt=""
                    width="50"
                  />
                </div>
                <div className="single-cta">
                  <div className="cta-text">
                    <h4>Find us</h4>
                    <span>
                      2-3-4,Near Lord Shiva temple, Main road, Samalkot
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 mb-30 d-flex justify-content-center">
              <div className="location">
                <div>
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/1538/1538493.png"
                    alt=""
                    width="50"
                  />
                </div>
                <div className="single-cta">
                  <div className="cta-text">
                    <h4>Call us</h4>
                    <span>9951994005</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4 mb-30 d-flex justify-content-center">
              <div className="location">
                <div>
                  <img
                    src="https://cdn-icons-png.flaticon.com/512/732/732200.png"
                    alt=""
                    width="50"
                  />
                </div>
                <div className="single-cta">
                  <div className="cta-text">
                    <h4>Mail us</h4>
                    <span>ram@botsedge.com</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="footer-content pt-5 pb-5">
          <div className="row">
            <div className="col-xl-5 col-lg-5 mb-50">
              <div className="footer-widget">
                <div className="footer-logo">
                  <img src={Logo} className="img-fluid" alt="logo" />
                </div>
                <div className="footer-text">
                  <p>
                    You can easily deploy an AI chatbot on your customers'<br></br>
                    preferred channels, such as your website, WhatsApp, Facebook<br></br>
                    Messenger, or Live Chat.
                  </p>
                </div>
                <div className="footer-textt">
                  
                  <p><img src={phone} className="img-fluid" alt="map" /><Link to='tel:+91 9951994005'>+91 9951994005</Link></p>
                  <p><img src={email} className="img-fluid" alt="map" /><Link to='mailto:ram@botsedge.com'>ram@botsedge.com</Link></p>
                  <p>
                  <img src={map} className="img-fluid" alt="map" />2-3-4,Near Lord Shiva temple, Main road, Samalkot.
                  </p>

                </div>
                {/* <div className="footer-social-icon">
                  <span>Follow us</span>
                  <ul className="social-icon">
                    <li className="">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/3670/3670032.png"
                        alt=""
                        width="30"
                      />
                    </li>
                    <li className="">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/1384/1384063.png"
                        alt=""
                        width="30"
                      />
                    </li>
                    <li className="">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/2504/2504923.png"
                        alt=""
                        width="30"
                      />
                    </li>
                    <li className="">
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/3128/3128310.png"
                        alt=""
                        width="30"
                      />
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 mb-30">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Platform</h3>
                </div>
                <ul>
                  <li><Link to='/'>Home</Link></li>
                  <li><Link to='#'>Services</Link></li>
                  <li><Link to='#'>Blogs</Link></li>
                  <li><Link to='#'>Contact</Link></li>
                </ul>
              </div>
            </div>

            <div className="col-xl-2 col-lg-2 col-md-2 mb-30">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Support</h3>
                </div>
                <ul>
                <li><Link to='#'>Contact Us</Link></li>
                <li><Link to='#'>About Us</Link></li>
                <li><Link to='#'>Expert Team</Link></li>
                <li><Link to='#'>Privacy Policies</Link></li>
                </ul>
              </div>
            </div>

            <div className="col-xl-2 col-lg-2 col-md-2 mb-30">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Follow</h3>
                </div>
                <ul>
                  <li><Link to='#'>Facebook</Link></li>
                  <li><Link to='#'>Instagram</Link></li>
                  <li><Link to='#'>Linkedin</Link></li>
                  <li><Link to='#'>witter</Link></li>

                </ul>
              </div>
            </div>
            {/* <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Platform</h3>
                </div>
                <ul>
                  <li>Home</li>
                  <li>Services</li>
                  <li>Blogs</li>
                  <li>Contact</li>
                </ul>
              </div>
            </div> */}
            {/* <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Subscribe</h3>
                </div>
                <div className="footer-text mb-25">
                  <p>
                    Don’t miss to subscribe to our new feeds, kindly fill the
                    form below.
                  </p>
                </div>
                <div className="subscribe-form">
                  <form action="#">
                    <input type="text" placeholder="Email Address" />
                    <button>
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/1372/1372798.png"
                        alt=""
                        width="23"
                      />
                    </button>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 text-center text-lg-center">
              <div className="copyright-text">
                <p>Copyright &copy; 2023, All Right Reserved</p>
              </div>
            </div>
            {/* <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
              <div className="footer-menu">
                <ul>
                  <li>Home</li>
                  <li>Terms</li>
                  <li>Privacy</li>
                  <li>FAQ</li>
                  <li>
                    <Link to="/privacy">Privacy Policies</Link>
                  </li>
                  <li>Contact</li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
